import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This lesson provides an introduction to named entities (NEs).`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <ul>
      <li parentName="ul">{`identify types of named entities`}</li>
      <li parentName="ul">{`identify two popular tagging formats for named entity labels`}</li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <p><em parentName="p">{`What is a named entity`}</em>{`?`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Named_entity",
        "target": "_self",
        "rel": "nofollow"
      }}>{`named entity (NE)`}</a>{` is a textual mention that has a unique/individual identity (ex. "Danny DeVito" but not "actors"). `}</p>
    <p>{`Commonplace examples include mentions of ...`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`people`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Dio Brando, Inigo Montoya, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`locations`}</p>
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Tucson, AZ`}</em>{`, `}<em parentName="li">{`Hogwarts`}</em>{`, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`organizations`}</p>
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`OpenAI`}</em>{`, `}<em parentName="li">{`University of Arizona`}</em>{`, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`products`}</p>
        <ul parentName="li">
          <li parentName="ul">{`RTX Titan, Subaru Forester, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Geopolitical entities (GPE)`}</p>
        <ul parentName="li">
          <li parentName="ul">{`"The `}<strong parentName="li">{`White House`}</strong>{` announced that ..."`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Other examples include ...`}</p>
    <ul>
      <li parentName="ul">{`medical codes`}<sup parentName="li" {...{
          "id": "fnref-1"
        }}><a parentName="sup" {...{
            "href": "#fn-1",
            "className": "footnote-ref"
          }}>{`1`}</a></sup></li>
    </ul>
    <ul>
      <li parentName="ul">{`time expressions`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`January`}</em>{`, `}<em parentName="li">{`2020`}</em>{`, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`numerical expressions`}
        <ul parentName="li">
          <li parentName="ul">{`prices, measurements, etc.`}</li>
          <li parentName="ul"><em parentName="li">{`$10.99`}</em>{`, `}<em parentName="li">{`23 kg`}</em>{`, etc.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Often in the literature, named entities will simply be referred to as `}<strong parentName="p">{`entities`}</strong>{`.`}</p>
    <p>{`What constitutes a named entity depends on the domain of interest.  For example, if you're wanting to identify entities relevant to the biomedical literature, you may wish to include things such as ...`}</p>
    <ul>
      <li parentName="ul">{`disease names`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`SARS-CoV-2`}</em>{`, `}<em parentName="li">{`melanoma`}</em>{`, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`viruses`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`COVID-19`}</em>{`, `}<em parentName="li">{`H1N1`}</em>{`, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`proteins and genes`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`HRAS`}</em>{`, `}<em parentName="li">{`FOXP2`}</em>{`, etc.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`The task of identitifying named entities is known as named entity recognition.`}</p>
    <p><em parentName="p">{`Where are named entities used`}</em>{`?`}</p>
    <p>{`Named entity recognition is a subtask of information extraction called entity extraction.  Entity extraction has applications in ...`}</p>
    <ul>
      <li parentName="ul">{`question answering `}
        <ul parentName="li">
          <li parentName="ul">{`"Who was the 16th president of the United States?"`}</li>
        </ul>
      </li>
      <li parentName="ul">{`content recommendation`}
        <ul parentName="li">
          <li parentName="ul">{`People who read `}<em parentName="li">{`x`}</em>{` tend to enjoy `}<em parentName="li">{`y`}</em></li>
        </ul>
      </li>
      <li parentName="ul">{`document summarization`}
        <ul parentName="li">
          <li parentName="ul">{`who, when, where, what, etc.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`... and many other tasks that involve some form of information extraction (chat bots)`}</p>
    <h1 {...{
      "id": "tagging-formats-for-name-entities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tagging-formats-for-name-entities",
        "aria-label": "tagging formats for name entities permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tagging formats for name entities`}</h1>
    <p>{`If you want to develop a named entity recognizer for a new domain, you will likely need to gather and annotate data.`}</p>
    <p>{`There are a few common tagging formats for entities.  Here we'll look at the two most popular formats ...`}</p>
    <h2 {...{
      "id": "iob",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iob",
        "aria-label": "iob permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IOB`}</h2>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Inside%E2%80%93outside%E2%80%93beginning_(tagging)",
        "target": "_self",
        "rel": "nofollow"
      }}>{`The `}<strong parentName="a">{`I`}</strong>{`nside-`}<strong parentName="a">{`O`}</strong>{`utside-`}<strong parentName="a">{`B`}</strong>{`eginning (IOB) tagging format`}</a>{` is designed to handle multiword entities:`}</p>
    <p>{`Each entity type serves as a label and is prepended with either `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`I`}</code>{` depending on whether it starts a mention or continues a mention.  If a token is not any kind of an entity, it is assigned the label `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`O`}</code>{`:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Vincent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`van`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Gogh`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`was`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`born`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`in`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`the`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Netherlands`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`B-PER`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`I-PER`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`I-PER`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`B-LOC`}</code></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "notes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#notes",
        "aria-label": "notes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Notes`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`PER`}</code>{` in the example refers to a mention of a person (`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`PERSON`}</code>{`).`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`LOC`}</code>{` in the example refers to a mention of a location (`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`LOCATION`}</code>{`).`}</li>
    </ul>
    <p>{`One variation of the IOB format adds an additional label `}<strong parentName="p">{`E`}</strong>{` for the `}<strong parentName="p">{`e`}</strong>{`nd of some entity mention:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Vincent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`van`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Gogh`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`was`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`born`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`in`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`the`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Netherlands`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`B-PER`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`I-PER`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`E-PER`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`B-LOC`}</code></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "basic",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic",
        "aria-label": "basic permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic`}</h2>
    <p>{`There is a simpler, alternative format to IOB that is sometimes encountered where just the entity label is used without any prefix and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`O`}</code>{` for non-entities:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Vincent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`van`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Gogh`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`was`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`born`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`in`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`the`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Netherlands`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`PERSON`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`PERSON`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`PERSON`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`O`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`LOCATION`}</code></td>
        </tr>
      </tbody>
    </table>
    <p>{`Proponents of this format argue that immediately adjacent entities are exceedingly rare. As such, IOB adds unnecessary complexity.  `}</p>
    <p>{`In this format, one assumes that any contiguous sequence of the same label constitutes a single entity (i.e., `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Vincent/PERSON, van/PERSON, Gogh/PERSON`}</code>{` `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`→`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\rightarrow`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span></span></span></span></span>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<person>Vincent van Gogh</person>`}</code>{`).`}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <h2 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Entity types of interest vary from domain to domain.  Imagine your are developing a named entity recognizer to process academic papers about linguistics.  Name three entity labels you might want to include.  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Compose three sentences where each sentence contains a different Geopolitical Entity (GPE).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Can you think of any ambiguous cases where a word or phrase could be assigned either one of two (or more) named entity labels?`}</p>
      </li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`See `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Diagnosis_code",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://en.wikipedia.org/wiki/Diagnosis_code`}</a>{` `}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      